import styles from '../ExpandableMenu.module.css';

export type ExpandableMenuItemProps = {
  children: React.ReactNode;
  id?: string;
  isActive?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

export function ExpandableMenuItem({
  children,
  id,
  isActive = false,
  onMouseEnter,
  onMouseLeave,
}: ExpandableMenuItemProps): JSX.Element {
  return (
    <li
      id={id}
      className={styles.ExpandableMenuItem}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-is-active={isActive}
      data-testid="expandableMenuItem-li"
    >
      {children}
    </li>
  );
}
