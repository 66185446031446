import { useEffect, useMemo, useState } from 'react';
import IconDropdown from '../../../assets/svg/dropdown.svg';
import styles from '../ExpandableMenu.module.css';
import { ExpandableMenuItem } from '../ExpandableMenuItem/ExpandableMenuItem';

export type ExpandableMenuDropdownProps = {
  children?: React.ReactNode;
  dropdownTriggerTitle?: string;
  isActive?: boolean;
  isMobile?: boolean;
};

export function ExpandableMenuDropdown({
  children,
  dropdownTriggerTitle = 'Plus',
  isActive,
  isMobile,
}: ExpandableMenuDropdownProps): JSX.Element {
  const [isTriggerHovered, setIsTriggerHovered] = useState<boolean>(false);
  const [isDropdownHovered, setIsDropdownHovered] = useState<boolean>(false);
  const isDropdownDisplayed = useMemo(
    () => isTriggerHovered || isDropdownHovered,
    [isDropdownHovered, isTriggerHovered]
  );

  // Close dropdown after any click
  useEffect(() => {
    if (isDropdownDisplayed) {
      const onDocumentClick = () => {
        setIsTriggerHovered(false);
        setIsDropdownHovered(false);
      };
      document.addEventListener('click', onDocumentClick);
      return () => document.removeEventListener('click', onDocumentClick);
    }
    return;
  }, [isDropdownDisplayed]);

  return (
    <ExpandableMenuItem
      isActive={isActive}
      onMouseEnter={() => {
        if (!isMobile) {
          setIsTriggerHovered(true);
        }
      }}
      onMouseLeave={() => setIsTriggerHovered(false)}
    >
      {/* Dropdown trigger */}
      <button
        type="button"
        data-testid="dropdown-button"
        aria-expanded={isDropdownDisplayed}
        className={styles.ExpandableMenuItem__dropdownTrigger}
        onClick={() => setIsTriggerHovered(!isTriggerHovered)}
      >
        {dropdownTriggerTitle}
        <IconDropdown />
      </button>

      {/* Dropdown menu */}
      {isDropdownDisplayed && (
        <ul
          data-testid="expandable-menu-item-dropdown"
          className={styles.ExpandableMenuItem__dropdown}
          onMouseEnter={() => setIsDropdownHovered(true)}
          onMouseLeave={() => setIsDropdownHovered(false)}
        >
          {children}
        </ul>
      )}
    </ExpandableMenuItem>
  );
}
