import { Ratio, isSomeEnum } from '@canalplus/mycanal-commons';
import { ExpandableMenu, Heading } from '@canalplus/mycanal-sharedcomponent';
import { Binder, enter, memory, spatial } from '@canalplus/one-navigation';
import { PersoLists, Template } from '@canalplus/sdk-hodor';
import { ApiV2PageRubrique } from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/gabarit_list/definitions';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import ErrorTemplate from '../../../components/Error/ErrorTemplate';
import GabaritListItem from '../../../components/GabaritList/GabaritListItem';
import { DATA_BINDER_GABARIT_LIST_TAB } from '../../../constants/gabaritList';
import { myCanalTitlesMapping } from '../../../constants/international';
import {
  useAppHistory,
  useAppLocation,
} from '../../../helpers/hooks/reactRouter';
import { useOnFocusable } from '../../../helpers/hooks/useOnFocusable';
import {
  HEADING_HEIGHT,
  scroll,
  scrollFocusedIntoHorizontalList,
  scrollFocusedIntoVerticalViewport,
} from '../../../helpers/oneNavigation/scroll';
import { hideTabHorizontalNavigation } from '../../../helpers/oneNavigation/tabs';
import {
  isMobileSelector,
  offerLocationSelector,
} from '../../../store/slices/application-selectors';
import { displayTVModeSelector } from '../../../store/slices/displayMode-selectors';
import { pagePathSelector } from '../../../store/slices/page-selectors';
import { isMobileResolutionSelector } from '../../../store/slices/ui-selectors';
import LoadableContentGrid from '../../../templates/ContentGrid';
import LoadableContentGridPerso from '../../../templates/ContentGridPerso';
import LoadableLandingV5 from '../../../templates/LandingV5';
import LoadableLiveGrid from '../../../templates/LiveGrid';
import StubContainer from '../../../templates/Stub/components/StubContainer';
import styles from './GabaritListTemplate.css';

type GabaritListTemplateProps = {
  hideRubrics?: boolean;
  imageRatio?: Ratio;
  onRubricsChange?: (arg: ApiV2PageRubrique) => void;
  rubrics?: ApiV2PageRubrique[];
  onFocusable?: () => void;
  title?: string | null;
};

function GabaritListTemplate({
  hideRubrics = false,
  imageRatio = Ratio.Ratio169,
  onRubricsChange,
  rubrics = [],
  onFocusable,
  title,
}: GabaritListTemplateProps): JSX.Element | null {
  const visibleRubricIndex = rubrics?.findIndex((rubric) => rubric.default);
  const defaultVisibleRubricIndex =
    visibleRubricIndex >= 0 ? visibleRubricIndex : 0;
  const [rubricsActiveIndex, setRubricsActiveIndex] = useState(
    defaultVisibleRubricIndex
  );

  const pagePathname = useSelector(pagePathSelector);
  const [isTabVisible, setIsTabVisible] = useState<boolean>(true);

  const MIDDLEWARE_MENU = useMemo(
    () => [
      hideTabHorizontalNavigation(setIsTabVisible),
      scroll([
        scrollFocusedIntoVerticalViewport({
          margins: { top: HEADING_HEIGHT + 1.875 }, // 30px GabaritList menu margin-top
        }),
        scrollFocusedIntoHorizontalList('#expandableMenu__list'),
      ]),
      enter({
        forceFocusIndex: defaultVisibleRubricIndex,
        shouldForceFocusOnce: true,
      }),
      memory(),
      spatial(),
    ],
    [defaultVisibleRubricIndex, setIsTabVisible]
  );

  const offerLocation = useSelector(offerLocationSelector);
  const myCanalTitle = myCanalTitlesMapping[offerLocation];
  const isMobileResolution = useSelector(isMobileResolutionSelector);
  const isMobile = useSelector(isMobileSelector);
  const isTvDevice = useSelector(displayTVModeSelector);

  const { GabaritList, LiveGrid, Stub, ContentGrid, Landing } = Template;

  const history = useAppHistory();
  const location = useAppLocation();

  const activeRubric = rubrics[rubricsActiveIndex];
  const titleTemplate = `${title || ''}: ${activeRubric?.displayName || ''} | ${myCanalTitle || ''}`;

  useEffect(() => {
    const { pathname, search } = location;
    // firstParamOfPagePathname get '/live/' of '/live/tab/cinema'
    const firstParamOfPagePathname = pagePathname.replace(
      /(\/.[^/]*\/)(.*)/,
      '$1'
    );
    // To know if the user leaves the page we compare the location.pathname (target) with the page.pathname (current).
    // In this case, we prevent the update of the rubric to avoid redoing a call to a tab and triggering an unnecessary tracking page.
    const isPageLeft = !pathname.startsWith(firstParamOfPagePathname);
    if (isPageLeft) {
      return;
    }

    const pathnameWithParams = decodeURI(pathname + search);
    const currentRubricIndex = rubrics?.findIndex(
      (rubric) => rubric.path === pathnameWithParams
    );

    const isOpenImmersiveContext =
      location?.state?.immersive?.context === 'immersive';

    if (!isTvDevice && !isOpenImmersiveContext && currentRubricIndex === -1) {
      // if pathname is not in rubrics, it certainly the default hodor path
      setRubricsActiveIndex(defaultVisibleRubricIndex);
    } else if (!isTvDevice && currentRubricIndex >= 0) {
      // if pathname is in rubrics, so take the good rubric
      setRubricsActiveIndex(currentRubricIndex);
    }
    setIsTabVisible(true);
  }, [
    defaultVisibleRubricIndex,
    history,
    isTvDevice,
    location,
    rubrics,
    rubricsActiveIndex,
    pagePathname,
    setIsTabVisible,
  ]);

  useEffect(
    () => {
      // Today it's only used on the livetv old player because the livetv does not fetch new hodor url when changing rubric, so tracking is done here
      if (onRubricsChange && typeof activeRubric?.idRubrique !== 'undefined') {
        onRubricsChange(activeRubric);
      }
    },
    // We want to send the tracking on component mount AND when the active rubric changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeRubric?.idRubrique]
  );

  const switchTab = useCallback(
    (index = 0) => {
      setIsTabVisible(true);
      setRubricsActiveIndex(index);
    },
    [setIsTabVisible, setRubricsActiveIndex]
  );

  useOnFocusable(onFocusable, rubrics.length > 0);

  const renderDisplayTemplate = () => {
    const {
      displayAllChannels,
      displayOnlyFavoriteChannels,
      displayTemplate,
      parameters,
      perso,
      URLPage,
    } = activeRubric || {};

    const displayPlaceholderChannelsForLiveGrid =
      displayAllChannels || displayOnlyFavoriteChannels;
    const persoType = isSomeEnum(PersoLists)(perso) ? perso : undefined;

    switch (displayTemplate) {
      // type ApiV2PageRubrique
      case ContentGrid:
        if (perso && URLPage) {
          return (
            <LoadableContentGridPerso
              from={Template.GabaritList}
              persoType={persoType}
              url={URLPage}
              onClickParameters={parameters}
            />
          );
        } else {
          return (
            <LoadableContentGrid
              displayParameters={{ imageRatio }}
              from={GabaritList}
              url={URLPage}
              onClickParameters={parameters}
            />
          );
        }

      case LiveGrid:
        return (
          <LoadableLiveGrid
            displayPlaceholderChannels={displayPlaceholderChannelsForLiveGrid}
            from={Template.GabaritList}
            isPerso={!!perso}
            url={URLPage}
            onClickParameters={parameters}
          />
        );

      case Landing:
        return (
          <LoadableLandingV5
            from={Template.GabaritList}
            url={URLPage}
            onClickParameters={parameters}
          />
        );

      case Stub:
        return URLPage ? (
          <StubContainer
            from={Template.GabaritList}
            url={URLPage}
            onClickParameters={parameters}
          />
        ) : null;

      default:
        return <ErrorTemplate />;
    }
  };

  if (!rubrics.length) {
    return null;
  }
  return (
    <div className={styles.gabaritListTemplate}>
      <Helmet titleTemplate={titleTemplate} />
      <div
        className={classNames(
          styles.gabaritListTemplate__header,
          styles['gabaritListTemplate__header--multiline']
        )}
      >
        {!isTvDevice && title && <Heading text={title} isBig />}
        {rubrics.length > 1 && !hideRubrics && (
          <Binder
            middleware={MIDDLEWARE_MENU}
            data-binder={DATA_BINDER_GABARIT_LIST_TAB}
          >
            <ExpandableMenu
              activeIndex={rubricsActiveIndex}
              isDropdownEnabled={
                !(isMobile && isMobileResolution) && !isTvDevice
              }
              themeClass={styles.expandableMenuTheme}
              isMobile={isMobile}
              isTvDevice={isTvDevice}
            >
              {rubrics.map((item, index) => (
                <GabaritListItem
                  key={`${String(item.idRubrique)}-${String(index)}`}
                  item={item}
                  onClick={() => switchTab(index)}
                  id={String(item.idRubrique)}
                  navigateOnFocus={isTvDevice && rubricsActiveIndex !== index}
                />
              ))}
            </ExpandableMenu>
          </Binder>
        )}
      </div>
      <div
        className={classNames(styles.gabaritListTemplate__tabPanel, {
          [styles['gabaritListTemplate__tabPanel--hidden']]: !isTabVisible,
        })}
      >
        {renderDisplayTemplate()}
      </div>
    </div>
  );
}

export default GabaritListTemplate;
