import { throttle } from '@canalplus/mycanal-commons';
import classNames from 'classnames';
import {
  Children,
  Fragment,
  cloneElement,
  isValidElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styles from './ExpandableMenu.module.css';
import { ExpandableMenuDropdown } from './ExpandableMenuDropdown/ExpandableMenuDropdown';
import {
  ExpandableMenuItem,
  ExpandableMenuItemProps,
} from './ExpandableMenuItem/ExpandableMenuItem';

const EXPANDABLE_MENU_THROTTLE_DURATION = 250; // ms

enum DropdownTriggerPositions {
  NOT_YET_DETERMINED = -2,
  NOT_USED = -1,
}

export type ExpandableMenuProps = {
  activeIndex?: number;
  children?: React.ReactElement[];
  dropdownTriggerTitle?: string;
  isDropdownEnabled?: boolean; // Menu scrolls horizontally when false
  themeClass?: string;
  isMobile?: boolean;
  isTvDevice?: boolean;
};

export function ExpandableMenu({
  children = [],
  activeIndex = -1,
  dropdownTriggerTitle,
  isDropdownEnabled = true,
  themeClass = '',
  isMobile,
  isTvDevice = false,
}: ExpandableMenuProps): JSX.Element {
  const menuParentRef = useRef<HTMLElement>(null);
  const measurableListRef = useRef<HTMLUListElement>(null);
  const visibleListRef = useRef<HTMLUListElement>(null);

  /* Special fix for intergalactic bug 2.0 (see the old Navigation sharedcomponent for 1.0).
     Basically, there was an inconsistency between the SSR & CSR, which caused a wrong
     css classnames to be applied, which broke the UI.  This is fixed by only rendering
     the visible list once we are client side and after the first render. */
  const [isInitiatedClientSide, setIsInitiatedClientSide] = useState(false);
  useEffect(() => {
    setIsInitiatedClientSide(true);
  }, []);

  const dropdownMode = isDropdownEnabled && isInitiatedClientSide;

  // splitIndex determines what goes in the horizontal and dropdown lists
  const [splitIndex, setSplitIndex] = useState<
    number | DropdownTriggerPositions
  >(
    dropdownMode
      ? DropdownTriggerPositions.NOT_YET_DETERMINED
      : DropdownTriggerPositions.NOT_USED
  );

  const getMenuParentWidth = useCallback(() => {
    if (menuParentRef && menuParentRef.current) {
      return menuParentRef.current.offsetWidth;
    }
    return 0;
  }, [menuParentRef]);

  const getVisibleMenuWidth = useCallback(() => {
    if (visibleListRef && visibleListRef.current) {
      return visibleListRef.current.offsetWidth;
    }
    return 0;
  }, [visibleListRef]);

  const getActiveElement = useCallback(() => {
    const listElement = visibleListRef.current;
    if (listElement && activeIndex >= 0) {
      return listElement.children[activeIndex];
    }
    return null;
  }, [visibleListRef, activeIndex]);

  // Determines where the menu needs to split its contents, if they're too long to all fit
  const calculateSplitIndex = throttle(() => {
    if (
      menuParentRef &&
      menuParentRef.current &&
      measurableListRef &&
      measurableListRef.current
    ) {
      const menuWidth = getMenuParentWidth();
      const menuItems: Element[] = Array.from(
        measurableListRef.current.children
      );

      const dropdownTriggerEl = menuItems.pop(); // In the measurable list, the dropdown trigger is the last element
      const dropdownTriggerWidth = dropdownTriggerEl
        ? dropdownTriggerEl.clientWidth
        : 0;
      const menuItemWidths = menuItems.map((el) => el.clientWidth);

      // Dropdown is used when all menu items do not fit within the menu width
      const isDropdownNeeded =
        menuItemWidths.reduce((total, num) => total + num, 0) > menuWidth;
      if (isDropdownNeeded) {
        // Find where to insert the dropdown trigger into the list of menu items so it's as far right as possible
        let childrenWithDropdownWidth = dropdownTriggerWidth;
        for (let i = 0; i < menuItemWidths.length; i += 1) {
          childrenWithDropdownWidth += menuItemWidths[i];
          if (childrenWithDropdownWidth > menuWidth) {
            if (i !== splitIndex) {
              setSplitIndex(i);
            }
            break;
          }
        }
      } else if (
        !isDropdownNeeded &&
        splitIndex !== DropdownTriggerPositions.NOT_USED
      ) {
        setSplitIndex(DropdownTriggerPositions.NOT_USED);
      }
    }
  }, EXPANDABLE_MENU_THROTTLE_DURATION);

  // Each child gets wrapped in an ExpandableMenuItem component
  const menuItemsInteractive = useMemo(
    () =>
      Children.map(children || [], (child, index) => (
        <ExpandableMenuItem
          isActive={index === activeIndex}
          id={`expandableMenuItem_${index}`}
          key={`expandableMenuItem_${String(index)}`}
        >
          {isValidElement<ExpandableMenuItemProps>(child) &&
            cloneElement(child, { id: `expandableMenuItem_${index}_onclick` })}
        </ExpandableMenuItem>
      )),
    [activeIndex, children]
  );

  // The measurable menu items get props removed that determine ID attribute (don't want duplicates in the DOM)
  const menuItemsMeasurable = useMemo(
    () =>
      Children.map(children || [], (child, index) => (
        <ExpandableMenuItem
          isActive={index === activeIndex}
          key={`measurableMenuItem_${String(index)}`}
        >
          {isValidElement<ExpandableMenuItemProps & { title?: string }>(
            child
          ) && cloneElement(child, { id: undefined, title: undefined })}
        </ExpandableMenuItem>
      )),
    [activeIndex, children]
  );

  const interactiveMenuItems = useMemo(() => {
    if (splitIndex === DropdownTriggerPositions.NOT_YET_DETERMINED) {
      return null; // The component just mounted and we're calculating menu item sizes, so we don't yet display the interactive menu items
    }
    if (splitIndex === DropdownTriggerPositions.NOT_USED) {
      return menuItemsInteractive;
    }
    const barItems = (menuItemsInteractive || []).slice(0, splitIndex);
    const dropdownItems = (menuItemsInteractive || []).slice(splitIndex);
    return (
      <Fragment>
        {barItems}
        <ExpandableMenuDropdown
          dropdownTriggerTitle={dropdownTriggerTitle}
          isActive={!!activeIndex && activeIndex >= splitIndex}
          isMobile={isMobile}
        >
          {dropdownItems}
        </ExpandableMenuDropdown>
      </Fragment>
    );
  }, [activeIndex, dropdownTriggerTitle, menuItemsInteractive, splitIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  // On mount and when layout mode changes: Measure elements and determine splitIndex
  useEffect(() => {
    if (!dropdownMode && splitIndex !== DropdownTriggerPositions.NOT_USED) {
      setSplitIndex(DropdownTriggerPositions.NOT_USED);
      return;
    }

    // Recalculate splitIndex whenever screen size changes
    if (dropdownMode) {
      // setTimeout because need wait first render
      setTimeout(() => {
        calculateSplitIndex();
      }, EXPANDABLE_MENU_THROTTLE_DURATION);

      window.addEventListener('resize', calculateSplitIndex);

      return () => {
        window.removeEventListener('resize', calculateSplitIndex);
      };
    }

    return;
  }, [dropdownMode, calculateSplitIndex, splitIndex]);

  const scrollToActiveMenuItem = useCallback(() => {
    const listElement = visibleListRef.current;
    const activeItem = getActiveElement();
    if (listElement && activeItem) {
      const menuWidth = getVisibleMenuWidth();
      const { clientWidth: activeItemWidth, offsetLeft: activeItemOffsetLeft } =
        activeItem as HTMLElement;
      const scrollToPosition =
        activeItemOffsetLeft + activeItemWidth / 2 - menuWidth / 2;
      listElement.scrollLeft = scrollToPosition; // Animation is handled by native css
    }
  }, [getActiveElement, getVisibleMenuWidth, visibleListRef]);

  // Scroll the list to center the active menu item when the activeIndex changes (only when the dropdown feature is disabled)
  useEffect(() => {
    if (!dropdownMode && !isTvDevice) {
      scrollToActiveMenuItem();
    }
  }, [activeIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <nav
      ref={menuParentRef}
      className={classNames(styles.ExpandableMenu, {
        [themeClass]: !!themeClass,
      })}
      role="navigation"
    >
      {/* (Visually hidden): full list of menu items used only to measure the size of menu items, in order the determine where the dropdown starts. */}
      {isDropdownEnabled && (
        <ul
          ref={measurableListRef}
          className={classNames(
            styles.ExpandableMenu__list,
            styles['ExpandableMenu__list--measureable']
          )}
        >
          {menuItemsMeasurable}
          <ExpandableMenuDropdown dropdownTriggerTitle={dropdownTriggerTitle} />
        </ul>
      )}

      {/* The interactive list with potential dropdown */}
      <ul
        ref={visibleListRef}
        className={classNames(styles.ExpandableMenu__list, {
          [styles['ExpandableMenu__list--scrollable']]: !dropdownMode,
        })}
        id="expandableMenu__list"
      >
        {interactiveMenuItems}
      </ul>
    </nav>
  );
}
